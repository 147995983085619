import React from "react";
import { CategoryLinkWrapper } from "./wrapper";
import { CategoryLink } from "./link";
import { categoryData } from "../../../../../utils";

export const CategoryLinkButton = ({ curIdx }) => {
  return (
    <CategoryLinkWrapper>
      <CategoryLink to={`/category/${categoryData[curIdx].slug}`}>
        {categoryData[curIdx].ko} 글들 보러가기
      </CategoryLink>
    </CategoryLinkWrapper>
  );
};
