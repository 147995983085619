import React from "react";
import { RecentPostsStickySection } from "../common/recent-posts/sticky";
import { CategoryNavigationSection } from "./category-nav";
import { CategoriesOverviewWrapper } from "./wrapper";

export const CategoriesOverviewSection = () => (
  <CategoriesOverviewWrapper>
    <CategoryNavigationSection />
    <RecentPostsStickySection />
  </CategoriesOverviewWrapper>
);
