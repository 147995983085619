import React from "react";
// import { TextContainer } from "./text";
import { NavWrapper } from "./wrapper";
import { ToggleContainer } from "./container";
import { ToggleButton } from "./button";
import { ButtonContainer } from "./list";
import { categoryData } from "../../../../utils";

const INFO_TEXTS = [
  "컴퓨터과학 카테고리 정보 조회",
  "개발 카테고리 정보 조회",
  "공식문서 카테고리 정보 조회",
  "에세이 카테고리 정보 조회",
];

export const CategoryNavigation = ({ curIdx, setIdx }) => (
  <NavWrapper>
    {/* <TextContainer> */}
      <ToggleContainer>
        {categoryData.map((category, idx) => (
          <ButtonContainer
            onClick={() => setIdx(idx)}
            chosen={`${curIdx === idx}`}
            key={idx}
            title={INFO_TEXTS[idx]}
          >
            <ToggleButton>{category.ko}</ToggleButton>
          </ButtonContainer>
        ))}
      </ToggleContainer>
    {/* </TextContainer> */}
  </NavWrapper>
);
