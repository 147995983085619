import React from "react";
import { CategoryInfoWrapper } from "./wrapper";
import { categoryData } from "../../../../utils";
import { CategoryStaticImage } from "./image";
import { Title } from "./title";
import { Description } from "./description";
import { CategoryLinkButton } from "./link";

export const CategoryInfoSection = ({ curIdx }) => {
  return (
    <CategoryInfoWrapper>
      <Title>{categoryData[curIdx].ko}</Title>
      <Description descriptions={categoryData[curIdx].description} />
      <CategoryLinkButton curIdx={curIdx} />
      <CategoryStaticImage curIdx={curIdx} />
    </CategoryInfoWrapper>
  );
};
