import React, { useState } from "react";
import { CategoryNavigationWrapper } from "./wrapper";
import { CategoryNavigation } from "./nav";
import { CategoryInfoSection } from "./category-info";

export const CategoryNavigationSection = () => {
  const [curIdx, setIdx] = useState(0);
  return (
    <CategoryNavigationWrapper>
      <CategoryNavigation curIdx={curIdx} setIdx={setIdx} />
      <CategoryInfoSection curIdx={curIdx} />
    </CategoryNavigationWrapper>
  );
};
