import styled from "styled-components";

export const CategoriesOverviewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    display: grid;
    grid-template-columns: 1fr ${({ theme }) =>
        `${theme.widthSize.RECENT_POSTS_LIST_WIDTH}px`};
  }

  ${({ theme }) => theme.preventUserSelect};
`;
