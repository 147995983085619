import styled from "styled-components";

export const CategoryInfoWrapper = styled.section`
  padding: 30px 10px 40px 10px;
  margin: 0 20px;

  z-index: 30;
  border-top: 1px solid ${({ theme }) => theme.colors.grayBorder};
  background-color: ${({ theme }) => theme.colors.backgroundColor};
`;
