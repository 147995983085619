import styled from "styled-components";

export const ToggleContainer = styled.ul`
  position: absolute;
  bottom: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  padding-right: 5px;

  height: 100%;
`;
