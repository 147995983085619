import styled from "styled-components";

export const ButtonContainer = styled.li`
  ${({ theme }) => theme.baseStyles(["padding"])};
  cursor: pointer;

  z-index: ${({ chosen }) => (chosen === "true" ? "50" : "10")};
  background-color: ${({ theme }) => theme.colors.backgroundColor};

  padding: 7px;
  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-bottom: none;

  margin-bottom: -1px;

  padding-bottom: ${({ chosen }) => (chosen === "true" ? "9px" : "5px")};

  margin-left: 2px;

  border-radius: 12px 12px 0 0;

  &:hover {
    padding-bottom: 12px;
    z-index: 50;
  }
`;
