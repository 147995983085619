import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { categoryData } from "../../../../../utils";
import { ImageWrapperStyle } from "./wrapper";

export const CategoryStaticImage = ({ curIdx }) => {
  const size = 900;
  const ratio = 2 / 1;
  return (
    <>
      {categoryData[curIdx].slug === "computer-science" && (
        <StaticImage
          src="./assets/computer-science.jpg"
          alt={`image for ${categoryData[curIdx].en}`}
          height={size}
          aspectRatio={ratio}
          imgStyle={ImageWrapperStyle}
          placeholder="dominantColor"
        />
      )}
      {categoryData[curIdx].slug === "development" && (
        <StaticImage
          src="./assets/development.jpg"
          alt={`image for ${categoryData[curIdx].en}`}
          height={size}
          aspectRatio={ratio}
          imgStyle={ImageWrapperStyle}
          placeholder="dominantColor"
        />
      )}
      {categoryData[curIdx].slug === "docs" && (
        <StaticImage
          src="./assets/essay.jpg"
          alt={`image for ${categoryData[curIdx].en}`}
          height={size}
          aspectRatio={ratio}
          imgStyle={ImageWrapperStyle}
          placeholder="dominantColor"
        />
      )}
      {categoryData[curIdx].slug === "essay" && (
        <StaticImage
          src="./assets/essay.jpg"
          alt={`image for ${categoryData[curIdx].en}`}
          height={size}
          aspectRatio={ratio}
          imgStyle={ImageWrapperStyle}
          placeholder="dominantColor"
        />
      )}
    </>
  );
};
