import styled from "styled-components";

export const CategoryLinkWrapper = styled.div`
  padding-bottom: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
`;
