import { Link } from "gatsby";
import styled from "styled-components";

export const CategoryLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.xl};
  padding: 10px;

  padding: 15px;

  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.lg};

  color: white;
  background-color: ${({ theme }) => theme.colors.thickHighlightColor};

  border-radius: 20px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.highlightColor};
  }
`;
