import React from "react";
import { DescriptionWrapper } from "./wrapper";
import { DescriptionText } from "./text";

export const Description = ({ descriptions }) => {
  return (
    <DescriptionWrapper>
      {descriptions.map((line, idx) => (
        <DescriptionText key={idx}>{line}</DescriptionText>
      ))}
    </DescriptionWrapper>
  );
};
