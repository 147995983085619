import React from "react";
// import { ProfileSection } from "../components/intro/profile";
import { CategoriesOverviewSection } from "../components/categories";
import SeoHelmet from "../components/seo";
import Layout from "../components/layout";

const Categories = () => {
  return (
    <>
      <SeoHelmet
        title="카테고리 정보"
        description={
          `해당 블로그의 모든 게시글은 컴퓨터과학, 개발, 에세이라는` +
          ` ` +
          `3가지 카테고리 중 하나에 해당됩니다.` +
          ` ` +
          `해당 페이지는 어떠한 기준으로 각 카테고리를 구성하였는지에 대한 설명입니다.`
        }
        pathname="/categories"
      />
      <Layout addDefaultMainAd={true}>
        {/* <ProfileSection /> */}
        <CategoriesOverviewSection />
      </Layout>
    </>
  );
};

export default Categories;
